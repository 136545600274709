import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Form from "../components/form";
import Container from "../styles/Container";
import HeaderText from "../components/HeaderText";

class ContactIndex extends React.Component {




  render() {

    return (
      <Layout >
        <SEO
          title="Contact"
          description={'Contact us to learn more about moving to digital employee handbook software.'}
        />
        <Container>
          <HeaderText
            title='Contact'
            text={`We'd love to hear from you! Whether you have a quick question or you're ready to get started, send us a note.`}
          />

        <Form />
        </Container>


      </Layout>
    )
  }
}

export default ContactIndex;
