import React from "react"
import styled from "styled-components";


const HeaderContainer = styled.header`
  max-width: ${props => props.theme.containerSm};
  margin: 0 auto;
`;

const HeaderH1 = styled.h1`
  font-size: ${props => props.theme.fontXxl};
`;

class HeaderText extends React.Component {
  render() {
    return (
      <HeaderContainer>
        <HeaderH1>{this.props.title}</HeaderH1>
        <p>{this.props.text}</p>
      </HeaderContainer>
    )
  }
}

export default HeaderText
